<template>
    <div class="newsadd">
        <div class="h72"></div>
        
        <div v-if="pdata.banner">
          <a class="consbanner" v-if="!pdata.banner.video" :href="pdata.banner.link?pdata.banner.link:'javascript:void(0);'" :target="pdata.banner.link?'_blank':'_self'">
              <img :src="$imgurl + sjh(pdata.banner.image)" alt="">
          </a>
          <div class="consbanner" v-else>
              <video id="videos" :poster="$imgurl + sjh(pdata.banner.image)" loop class="imgspc" width="100%" height="100%" autoplay="autoplay" muted :src="$imgurl + sjh(pdata.banner.video)"></video>
          </div>
        </div>
        <div class="newsadd-center">
            <div class="w1290 prenewadd">
              
              <div class="titles" v-if="pdata.workIntro">
                <div class="cn tit-36">{{pdata.workIntro.title}}</div>
                <div class="en tit-108">{{pdata.workIntro.second}}</div>
              </div>
              <div class="newsadd-list">

                    <div class="cons-swiper">
                      <img src="../assets/newadd/1.png" alt="" class="bg1">
                      <img src="../assets/newadd/2.png" alt="" class="bg2">
                        <div class="swiper-container">
                          <div class="swiper-wrapper">
                                <a v-for="(item,index) in pdata.workList" :href="item.link?item.link:'javascript:void(0);'" :target="item.link?'_blank':'_self'"  :key="index" class="swiper-slide items">
                                    <div class="images">
                                        <div class="imgs">
                                          <img class="i1" :src="$imgurl + sjh(item.image)" alt="">
                                          <img class="i0" src="../assets/newadd/7.png" alt="">  
                                      </div>
                                        <div class="words">
                                            <div class="tits tit-58">{{item.title}}</div>
                                            <div class="txts tit-38">
                                              {{item.second}}
                                            </div>
                                            <div class="text tit-15"> {{item.intro}}</div>
                                        </div>
                                    </div>
                                    <div class="span tit-32">
                                      立即体验
                                    </div>
                                  </a>
                          </div>
                        </div>
                        <div class="swiper-button-next swiper-button-white"></div>  
                        <div class="swiper-button-prev swiper-button-white"></div>  
                    </div>                 

              </div>
            </div>

        </div>

        <div class="newsadd-bottoms">
            <div class="w1290 prenewbottom">
              <div class="titles" v-if="pdata.toolIntro">
                <div class="cn tit-36">{{pdata.toolIntro.title}}</div>
                <div class="en tit-108">{{pdata.toolIntro.second}}</div>
              </div>
              
                <div class="itemboxlist">
                    <a v-for="(itadds,index) in pdata.toolList"   :href="itadds.link?itadds.link:'javascript:void(0);'" :target="itadds.link?'_blank':'_self'" class="items"  :key="index">
                        <div class="tits tit-20">
                          {{itadds.title}}
                        </div>
                        <div class="line"></div>
                        <div class="txts tit-15">
                          {{itadds.intro}}
                        </div>
                    </a>
                </div>
                <div class="h128"></div>
                <div class="itemboxlist-words" v-if="pdata.toolIntro">
                    <div class="words">
                        <div class="tits tit-36 con-flex">
                            <div class="icos">
                                <img :src="$imgurl + sjh(pdata.toolIntro.gptLogo)" alt="">
                            </div>
                            {{pdata.toolIntro.gptTitle}}
                        </div>
                        <a :href="pdata.messageLink?pdata.messageLink:'langchainPage?ind=3&id=40'" :target="pdata.messageLink?'_blank':'_self'" class="txts tit-20">
                          {{ pdata.toolIntro.gptIntro }}
                        </a>

                    </div>
                    <div class="images">
                      <img :src="$imgurl +  sjh(pdata.toolIntro.rightImg)" alt="">
                    </div>
                    {{  }}
                </div>
              
            </div>
          
        </div>
        
    </div>
 </template>
  
  <script>
  
  let WOW = require("wowjs");
  import Swiper from "swiper";
  export default {
    name: "Padd",
    data() {
    return {
      getimgurl:'https://www.hundsun.com/v1api/upload/',
      pdata:{},
      swiper: null,
      ie:null
    };
  },
  methods: {
    asscli(index) {
      clearInterval(this.timer);
      this.curindex = index;
      this.initSwiper();
    },
    initSwiper() {
      this.timer = setInterval(() => {
        this.curindex++;
        if (this.curindex > 4) {
          this.curindex = 0;
        }
      }, 3500);
    },
    changeLc(index) {
      this.$nextTick(() => {
        this.swiper.slideTo(0, 0, false);
        this.initSwipers();
      });
    },
    //数据请求
    getdata() {
      this.$fetch({
        url: "/index.php/paddapi",
        type: "get",
      }).then((res) => {
        this.pdata = res;
        // console.log(res)
        this.$nextTick(() => {
          let wow = new WOW.WOW({
            boxClass: "wow",
            animateClass: "animated",
            offset: 0,
            mobile: true,
            live: true,
          }).init();
          this.initSwipers();
        });
      });
    },
    initSwipers() {
      if (this.swiper) this.swiper.destroy(false);
      this.swiper = new Swiper(".swiper-container", {
        slidesPerView: 2,
        // centeredSlides: true,
        centeredSlidesBounds: true,
        // 如果需要分页器
        pagination: ".swiper-pagination",
        // 如果需要前进后退按钮
        nextButton: ".swiper-button-next",
        prevButton: ".swiper-button-prev",
        centeredSlidesBounds: true,
        spaceBetween: '11.24%',
        // 如果需要滚动条
        // scrollbar: '.swiper-scrollbar',
        // 如果需要自动切换海报
        // autoplay: {
        //   delay: 1000,//时间 毫秒
        //   disableOnInteraction: false,//用户操作之后是否停止自动轮播默认true
        // },
      });
    },
    setie() {
       var ua = window.navigator.userAgent
       if(ua == 'Mozilla/5.0 (Windows NT 10.0; WOW64; Trident/7.0; .NET4.0C; .NET4.0E; rv:11.0) like Gecko'){
        this.ie= true
       }
    },
    sjh(datas){
      return datas.split('/upload/')[1];
    }
  },
  watch: {
    $route(val, oldval) {
      //console.log(val,oldval)
      if (val.query.anch != oldval.query.anch) {
        let dom = val.query.anch;
        if (dom) this.$until.scrollAnch(this.$refs[dom]);
      }
    },
  },
  mounted() {
    let dom = this.$route.query.anch;
    if (dom) this.$until.scrollAnch(this.$refs[dom]);
    //获取到配置的头部颜色，听过store监听动态改变
    this.$store.commit("until/SET_COLOR", "#000");
    this.initSwiper();
    //管理团队自动播放
    this.getdata();
    this.setie();
  },

  //跳转其他路由清除定时器：
  beforeDestroy() {
    clearInterval(this.timer);
    this.timer = null;
  },
  };
  </script>
  
  <style lang='scss' scoped>
    .newsadd-list .words .text{
      font-size: 16px;
    }
    .newsadd-bottoms{
      background:url(../assets/newadd/3.jpg) no-repeat center;
      padding-top:80px;
      background-size: cover;
      padding-bottom:180px;
    }
    .itemboxlist-words .words .txts{
      display: block;
      color: #fff;
      font-size: 18px;
    }

    .newsadd-center .items{
      .images{
        position: relative;
        .i0{
          display: block;
          opacity: 0;
          visibility: hidden;
        }
        .i1{
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          object-fit: cover;
        }
      }

    }
    .swiper-button-disabled{
      display: none!important;
    }
    .swiper-button-prev{
      left: -100px;
    }
    .swiper-button-next{
      right: -100px;
    }
    .swiper-button-prev,.swiper-button-next{
      width: 80px;
      height: 60px;
      background-size: 60%;
    }
    .newsadd-bottoms .titles .en{
      color: #263689;
    }
    .w1290{
      width:1290px;
      margin:0 auto;
    }
    .tit-36{
      font-size: 36px;
    }
    .tit-108{
      font-size: 108px;
    }
    $colors:#0a1a44;
    .newsadd-center{
      background-color: $colors;
      .newsadd-list{
        margin-top: 72px;
      }
    }
    .consbanner video{
      display: block;
    }
    .newsadd-center{
      .bg1{
        width:346px;
        height: 345px;
        left: -240px;
        bottom: -70px;
      }
      .bg1,.bg2{
        position: absolute;
      }
      .bg2{
        width:346px;
        height: 345px;
        top: -136px;
        right: -290px;
      }
    }
    .cons-swiper{
      position: relative;
    }
    .titles{
      text-align: center;
      position: relative;
      .cn{
          line-height: 1;
          color: #fff;
          position: absolute;
          left: 50%;
          top: 50%;
          transform:translate(-50%,-50%)
      }
      .en{
        color: #07225e;
        line-height: 1;
      }
    }
    img{
      width:100%;
    }
    .newsadd-center{
      padding-top: 90px;
      padding-bottom: 120px;
    }
    .h72{
        height: 72px;
    }
    .tit-58{
      font-size: 48px;
    }
    .tit-38{
      font-size:30px;
    }
    .tit-32{
      font-size: 32px;
    }
    .newsadd-list .images{
      position: relative
    }


    .newsadd-list{

    }

    .newsadd-list .words{
      position: absolute;
      left: 0;
      top: 0;
      color: #fff;
      padding: 60px 36px 0 36px;
      .tits,.txts{
        line-height: 1;
      }
      .txts{
        padding-bottom: 14px;
        border-bottom: 4px solid #fff;
        margin-bottom: 10px;
        display: inline-block;
        vertical-align: top;
      }
      .text{
        line-height: 2;
      }
      .txts{
        margin-top: 20px;
      }
    }
    .prenewadd{
      position: relative;
    }

  .newsadd-list .items{
    img{
      transition: .5s ease-in-out;
    }
    .images{
      overflow: hidden;
    }
    &:hover{
      img{
        transition: .5s ease-in-out;
        transform:scale3d(1.05,1.05,1);
      }
    }
  }

    .newsadd-list .span{
      line-height: 54px;
      height: 54px;
      color: #fff;
      text-align: center;
      //background-image: linear-gradient(to right,#e502e6, #29abe2);
      font-size: 30px;
      position: absolute;
      width: 380px;
      bottom: 36px;
      left: 0;
      right: 0;
      margin:auto;
      background-color:rgba(16,73,254,.5);
    }
    .tit-20{
      font-size: 20px;
    }
    .tit-14{
      font-size: 14px;
    }
    .h128{
      height: 128px;

    }
    .tit-15{
      font-size: 15px;
    }
    .itemboxlist-words{
      width:1290px;
      margin:0 auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: flex-start;
      .tits{
        display: flex;
      }
      .txts{
        margin-top: 38px;
        line-height: 1.4;
      }
      .words{
        width: 73%;
        color:#fff;
      }
      .icos{
        display: inline;
        margin-right: 18px;
        img{
          width:255px;
          height: 61px;
        }
      }
      .images{
        img{
          width:199px;
        }
      }
    }

    .itemboxlist{
      display: flex;
      justify-content: center;
      align-items: stretch;
      flex-wrap: wrap;
      width: 1290px;
      margin:0 auto;
      margin-top: 65px;
      .items{
        display: block;
        width: 366px;
        background: url(../assets/newadd/3.png) no-repeat center;
        background-size: 100% 100%;
        color: #fff;
        margin-left:96px;
        margin-bottom: 60px;
        box-sizing:border-box;
        padding: 30px 26px;
        padding-bottom: 38px;
        text-align: center;
        &:nth-child(3n+1){
          margin-left: 0;
        }
        .tits{
          text-align: center;
          line-height: 1.4;
        }
        .line{
          width: 50px;
          height: 2px;
          background: url(../assets/newadd/4.png) no-repeat center;
          background-size: 100%;
          margin:0 auto;
          margin-top: 10px;
          margin-bottom: 20px;
        }


      }
    }

    

    
    @media (min-width: 1280px){
        .h72{
        height: 72px;
        }
    }
  
  </style>